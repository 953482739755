import {CURRENCY, FieldType, GroupField} from "@/core/config/Constant";

export const GenericFieldConfig = [
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Currency',
        type: FieldType.SELECT,
        key: 'currency',
        subKey: '',
        id: 'currency',
        subName: '',
        data: CURRENCY,
      },
      {
        col: 'col-md-6',
        name: 'Date invoice received',
        type: FieldType.DATETIME,
        key: 'receivedDate',
        subKey: '',
        id: 'currency',
        subName: '',
      },
    ]
  },
  {
    heading: 'Service provider',
    type: GroupField.PROVIDER,
    key: 'payToId',
    subKey: '',
    service: false,
  },
  {
    heading: 'Billing Provider',
    type: GroupField.PROVIDER,
    key: 'providerId',
    subKey: '',
    service: true,
  },
  {
    heading: '',
    type: GroupField.LINES,
  },
  {
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-12',
        name: 'Note',
        type: FieldType.TEXTAREA,
        key: 'data',
        subKey: 'note',
        id: 'note',
        subName: '',
      },
    ]
  },
]