import { IBaseInvoice } from "@/core/entity/IBaseInvoice";

export interface IPrescriptionDrugs extends IBaseInvoice {
  totalClaim: number;
  clientPaid: number;
  cadAmount: number;
  rxNumber: string;
  exRate: number
}

export class ModelPrescriptionDrugs implements IPrescriptionDrugs {
  cadAmount: number = 0;
  exRate: number = 0;
  rxNumber: string = '';
  totalClaim: number = 0;
  clientPaid: number = 0;
  diag1: string = '';
  diag2: string = '';
  diag3: string = '';
  diag4: string = '';
  federalTaxNum: string = '';
  patientAccNumber: string = '';
  proc1: string = '';
  proc2: string = '';
  proc3: string = '';
  proc4: string = '';
  type: string = 'PRESCRIPT';
}