
import {computed, defineComponent} from 'vue'
import store from "@/store";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "FormCurrency",
  components: {BaseSelect},
  props: {
    clazz : {default: ''},
    modelValue: {},
    name: {default : 'currency'}
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const model = computed<any>({
      get() {
        return props.modelValue
      },
      set(model) {
        emit('update:modelValue', model)
      }
    })
    const currencies = computed(() => store.state.ExchangeRateModule.currencies);
    return {
      currencies,
      model,
    }
  }
})
