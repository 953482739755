import {CURRENCY, FieldType, GroupField} from "@/core/config/Constant";

export const DentalFieldConfig = [
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Currency',
        type: FieldType.SELECT,
        key: 'currency',
        subKey: '',
        id: 'currency',
        subName: '',
        data: CURRENCY,
      },
      {
        col: 'col-md-6',
        name: 'Date invoice received',
        type: FieldType.DATETIME,
        key: 'receivedDate',
        subKey: '',
        id: 'currency',
        subName: '',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: '[4] Other Dental Or Medical Coverage',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'otherDental',
        id: 'otherDental',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: '[5] Name of PolicyHolder',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'nameSubscriber',
        id: 'nameSubscriber',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: '[6] Date of Birth',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'coverageDob',
        id: 'coverageDob',
        subName: '',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: '[7] Gender',
        type: FieldType.RADIO,
        key: 'data',
        subKey: 'coverageGender',
        id: 'coverageGender',
        subName: '',
        data: [{value: 'M', name: 'M'}, {value: 'F', name: 'F'}]
      },
      {
        col: 'col-md-4',
        name: '[8] Policyholder/Subscription ID',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'coveragePolicyHolderId',
        id: 'coveragePolicyHolderId',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: '[9] Plan/Group Number',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'coverageGroupNumber',
        id: 'coverageGroupNumber',
        subName: '',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: '[9] Patient\'s Relationship to Person',
        type: FieldType.RADIO,
        key: 'data',
        subKey: 'coveragePatientRelationship',
        id: 'coveragePatientRelationship',
        subName: '',
        data: [
          {value: 'SELF', name: 'Self'},
          {value: 'SPOUSE', name: 'Spouse'},
          {value: 'DEPENDENT', name: 'Dependent'},
          {value: 'OTHER', name: 'Other'}
        ]
      },
      {
        col: 'col-md-6',
        name: '[9] Patient\'s Relationship to Person',
        type: FieldType.TEXTAREA,
        key: 'data',
        subKey: 'overageInsuranceCompany',
        id: 'overageInsuranceCompany',
        subName: '',
      },

    ]
  },
  {
    heading: '',
    type: GroupField.LINES
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: '[38] Place of Treatment',
        type: FieldType.RADIO,
        key: 'data',
        subKey: 'placeOfTreatment',
        id: 'placeOfTreatment',
        subName: '',
        data: [
          {value: 'PROVIDER', name: 'Provider'},
          {value: 'HOSPITAL', name: 'Hospital'},
          {value: 'ECF', name: 'ECF'},
          {value: 'OTHER', name: 'Other'}
        ]
      },
      {
        col: 'col-md-4',
        name: '[40] Is Treatment for Orthodontics',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isTreatmentOrthodontic',
        id: 'isTreatmentOrthodontic',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: '[41] Date Appliance Placed',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'dateAppliancePlaced',
        id: 'dateAppliancePlaced',
        subName: '',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: '[42] Month of Treatment Remain',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'monthsOfTreatmentRemain',
        id: 'monthsOfTreatmentRemain',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: '[43] Replacement Of Prosthesis',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'replacementOfPros',
        id: 'replacementOfPros',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: '[44] Date Prior Placement',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'datePriorPlacement',
        id: 'datePriorPlacement',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: '[45] Treatment Resulting',
        type: FieldType.RADIO,
        key: 'data',
        subKey: 'treatmentResulting',
        id: 'treatmentResulting',
        subName: '',
        data: [
          {value: 'OCCUPATIONAL', name :'Occupational illness'},
          {value: 'AUTO_ACCIDENT', name :'Auto Accident'},
          {value: 'OTHER_ACCIDENT', name :'Other Accident'},
        ]
      },
      {
        col: 'col-md-6',
        name: '[46] Date of Accident',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'dateOfAccident',
        id: 'dateOfAccident',
        subName: '',
      },
    ]
  },
  {
    heading: 'Billing Dentist',
    type: GroupField.PROVIDER,
    key: 'providerId',
    subKey: '',
    service: false,
  },
  {
    heading: 'Treatment Dentist',
    type: GroupField.PROVIDER,
    key: 'doctorId',
    subKey: '',
    service: false,
  },
]