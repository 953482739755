import { CURRENCY, FieldType, GroupField } from "@/core/config/Constant";

export const TripCanFieldConfig = [
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Currency',
        type: FieldType.SELECT,
        key: 'currency',
        subKey: '',
        id: 'currency',
        subName: '',
        data: CURRENCY,
      },
      {
        col: 'col-md-6',
        name: 'Date invoice received',
        type: FieldType.DATETIME,
        key: 'receivedDate',
        subKey: '',
        id: 'currency',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Original Amt Paid',
        type: FieldType.NUMBER,
        key: 'data',
        subKey: 'originalAmtPaid',
        id: 'originalAmtPaid',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Amt Refunded',
        type: FieldType.NUMBER,
        key: 'data',
        subKey: 'amtRefunded',
        id: 'amtRefunded',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Amt Claimed',
        type: FieldType.NUMBER,
        key: 'data',
        subKey: 'amtClaimed',
        id: 'amtClaimed',
        subName: '',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Trip departure date',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'departureDate',
        id: 'departureDate',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'Trip return date',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'returnDate',
        id: 'returnDate',
        subName: '',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Trip destination first',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'destinationFirst',
        id: 'destinationFirst',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'Date of Arrival',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'arrivalFirstDate',
        id: 'arrivalFirstDate',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'Trip destination second',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'destinationSecond',
        id: 'destinationSecond',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'Date of Arrival',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'arrivalSecondDate',
        id: 'arrivalSecondDate',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'Trip destination third',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'destinationThird',
        id: 'destinationThird',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'Date of Arrival',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'arrivalThirdDate',
        id: 'arrivalThirdDate',
        subName: '',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-12',
        name: 'Trip Purchase from',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'purchaseFrom',
        id: 'purchaseFrom',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'Trip Deposit date',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'depositDate',
        id: 'depositDate',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'Trip Paid Date',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'tripPaidDate',
        id: 'tripPaidDate',
        subName: '',
      },
      {
        col: 'col-md-12',
        name: 'NONE MEDICAL',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'noneMedical',
        id: 'noneMedical',
        subName: '',
      },
      {
        col: 'col-md-12',
        name: 'Reason for cancellation',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'reasonCancellation',
        id: 'reasonCancellation',
        subName: '',
      },
    ]
  },
  {
    heading: 'Medical',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Date your sickness was first felt',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'sicknessFirstDate',
        id: 'sicknessFirstDate',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'Date your first consulted your doctor',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'firstConsultedDoctorDate',
        id: 'firstConsultedDoctorDate',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'Date you cancelled your trip',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'cancelledTrip',
        id: 'cancelledTrip',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'Date you knew you have to cancel',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'knewTocCancelTrip',
        id: 'knewTocCancelTrip',
        subName: '',
      },
      {
        col: 'col-md-12',
        name: 'What were your symptoms',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'symptoms',
        id: 'symptoms',
        subName: '',
      },
      {
        col: 'col-md-12',
        name: 'What was the diagnosis given by your doctor',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'doctorDiagnosis',
        id: 'doctorDiagnosis',
        subName: '',
      },
      {
        col: 'col-md-12',
        name: 'have  you had this sickness before',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'hadSicknessBefore',
        id: 'hadSicknessBefore',
        subName: '',
      },
    ]
  },
  {
    heading: 'Other insurance',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Was the trip purchased by a credit card',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isCreditCard',
        id: 'isCreditCard',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'If Yes, Credit Card Number',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'cardNumber',
        id: 'cardNumber',
        subName: '',
      },
    ],
  },
  {
    heading: 'REFUND/VOUCHER/CREDIT',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-12',
        name: 'Have you received any refund, a voucher or credit to future trips?',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isRefund',
        id: 'isRefund',
        subName: '',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.LINES,
  }
]