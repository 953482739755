import { IBaseInvoice } from "@/core/entity/IBaseInvoice";


export interface ITripCanInvoice extends IBaseInvoice {

  /**
   * trip departure date
   */
  departureDate: number;
  returnDate: number;

  /**
   * Trip destination first
   */
  destinationFirst: string;
  /**
   * Date of Arrival
   */
  arrivalFirstDate: number;

  /**
   * Trip destination second
   */
  destinationSecond: string
  /**
   * Date of Arrival
   */
  arrivalSecondDate: number

  /**
   * Trip destination third
   */
  destinationThird: string;

  /**
   * Date of Arrival
   */
  arrivalThirdDate: number;


  /**
   * Trip purchase from
   */
  purchaseFrom: string

  /**
   * Trip deposit from
   */
  depositDate: number

  /**
   * Trip Paid Date
   */
  tripPaidDate: number

  /**
   * NONE MEDICAL
   */
  noneMedical: string;


  /**
   * Reason for cancellation
   */
  reasonCancellation: string;

  /**
   * Date your sickness was first felt
   */
  sicknessFirstDate: number;


  /**
   * Date your first consulted your doctor
   */
  firstConsultedDoctorDate: number;

  /**
   * Date you cancelled your trip
   */
  cancelledTrip: number

  /**
   *  Date you knew you have to cancel
   */
  knewTocCancelTrip: number

  /**
   * What were your symptoms
   */
  symptoms: string;

  /**
   * What was the diagnosis given by your doctor
   */
  doctorDiagnosis: string

  /**
   * have  you had this sickness before
   */
  hadSicknessBefore: boolean
  originalAmtPaid: number;
  amtRefunded: number;
  amtClaimed: number;
  isCreditCard: boolean;
  cardNumber: string;
  isRefund: boolean;
}

export class ModelTripCanInvoice implements ITripCanInvoice {
  cardNumber: string = '';
  isCreditCard: boolean = false;
  isRefund: boolean = false;
  amtClaimed: number = 0;
  amtRefunded: number = 0;
  originalAmtPaid: number = 0;
  arrivalFirstDate: number = NaN;
  arrivalSecondDate: number = NaN;
  arrivalThirdDate: number = NaN;
  cancelledTrip: number = NaN;
  departureDate: number = NaN;
  depositDate: number = NaN;
  destinationFirst: string = '';
  destinationSecond: string = '';
  destinationThird: string = '';
  diag1: string = '';
  diag2: string = '';
  diag3: string = '';
  diag4: string = '';
  doctorDiagnosis: string = '';
  federalTaxNum: string = '';
  firstConsultedDoctorDate: number = NaN;
  hadSicknessBefore: boolean = false;
  knewTocCancelTrip: number = NaN;
  noneMedical: string = '';
  patientAccNumber: string = '';
  proc1: string = '';
  proc2: string = '';
  proc3: string = '';
  proc4: string = '';
  purchaseFrom: string = '';
  reasonCancellation: string = '';
  returnDate: number = NaN;
  sicknessFirstDate: number = NaN;
  symptoms: string = '';
  tripPaidDate: number = NaN;
  type = 'TRIP_CAN';
}