import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-check form-check-custom form-check-solid form-check-sm mt-2" }
const _hoisted_2 = ["name"]
const _hoisted_3 = {
  class: "form-check-label fw-bold",
  style: {"font-size":"10px!important"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "form-check-input",
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      name: _ctx.id,
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
    }, null, 40, _hoisted_2), [
      [_vModelCheckbox, _ctx.model]
    ]),
    _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.name), 1)
  ]))
}