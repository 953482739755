import { CURRENCY, FieldType, GroupField, LookupType } from "@/core/config/Constant";


export const HCFAFieldConfig = [
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Currency',
        type: FieldType.SELECT,
        key: 'currency',
        subKey: '',
        id: 'currency',
        subName: '',
        data: CURRENCY,
      },
      {
        col: 'col-md-6',
        name: 'Date invoice received',
        type: FieldType.DATETIME,
        key: 'receivedDate',
        subKey: '',
        id: 'currency',
        subName: '',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-1',
        name: '[1] MEDICARE:',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isMedicare',
        id: 'MEDICARE',
        subName: '(Medicare#)'
      },
      {
        col: 'col-md-1',
        name: 'MEDICAID:',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isMedicaid',
        id: 'MEDICAID',
        subName: '(Medicaid#)'
      },
      {
        col: 'col-md-1',
        name: 'TRICARE:',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'iisTriCare',
        id: 'TRICARE',
        subName: '(ID#Dod#)'
      },
      {
        col: 'col-md-1',
        name: 'CHAMPVA:',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isChamPva',
        id: 'CHAMPVA',
        subName: '(Member ID#)'
      },
      {
        col: 'col-md-1',
        name: 'HEALTH PLAN:',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isGroupHealthPlan',
        id: 'HEALTHPLAN',
        subName: '(ID#)'
      },
      {
        col: 'col-md-1',
        name: 'FECABLKLUNG:',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isFeCaBlkLung',
        id: 'FECABLKLUNG',
        subName: '(ID#)'
      },
      {
        col: 'col-md-1',
        name: 'OTHER:',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isOtherMedicare',
        id: 'OTHER',
        subName: '(ID#)'
      },
      {
        col: 'col-md-5',
        name: '[1a]. Insured\'s I.D number:',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insuredNumber',
        id: 'insuredNumber',
        subName: '(ID#)'
      }
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: '[9].Other insured\'s name:',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'otherInsuredName',
        id: 'otherInsuredName',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: '[9a]. Other Insured’S policy or group number',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'otherInsuredPolicyOrGroupNumber',
        id: 'otherInsuredPolicyOrGroupNumber',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: '[9b] Other insured’s date of birth',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'orderInsuredDob',
        id: 'orderInsuredDob',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: '[9b] other insured’s gender',
        type: FieldType.RADIO,
        key: 'data',
        subKey: 'orderInsuredGender',
        id: 'orderInsuredGender',
        subName: '',
        data: [{value: 'M', name: 'M'}, {value: 'F', name: 'F'}]
      },
      {
        col: 'col-md-4',
        name: '[9c] Employer’s name or school name',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'otherInsuredEmployerName',
        id: 'otherInsuredEmployerName',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: '[9d] Insurance plan name or program name',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insurancePlanNameOrProgramName',
        id: 'insurancePlanNameOrProgramName',
        subName: ''
      }
    ]
  },
  {
    heading: '[10]. Is patient\'s condition related to',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-2',
        name: '[10.a] employment?',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isEmployment',
        id: 'isEmployment',
        subName: ''
      },
      {
        col: 'col-md-2',
        name: '[10.b] Auto Accident',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isAutoAccident',
        id: 'isAutoAccident',
        subName: ''
      },
      {
        col: 'col-md-2',
        name: '[10.b] Auto Accident place?',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'autoAccidentPlace',
        id: 'autoAccidentPlace',
        subName: ''
      },
      {
        col: 'col-md-2',
        name: '[10.c] Other accident?',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isOtherAccident',
        id: 'isOtherAccident',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: '[10.d] Reserved for local use',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'reservedForLocalUse',
        id: 'reservedForLocalUse',
        subName: ''
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-2',
        name: '[11] Insured’s policy group feca#',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insuredPolicyGroupOrFeCaNumber',
        id: 'insuredPolicyGroupOrFeCaNumber',
        subName: ''
      },
      {
        col: 'col-md-2',
        name: '[11] Gender',
        type: FieldType.RADIO,
        key: 'data',
        subKey: 'insuredPolicyGroupOrFeCaGender',
        id: 'insuredPolicyGroupOrFeCaGender',
        subName: '',
        data: [{value: 'M', name: 'M'}, {value: 'F', name: 'F'}]
      },
      {
        col: 'col-md-2',
        name: '[11b] Employer’s name or school',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insuredPolicyGroupOrFeCaEmployerName',
        id: 'insuredPolicyGroupOrFeCaEmployerName',
        subName: '',
      },
      {
        col: 'col-md-2',
        name: '[11c] Insurance plan name...',
        fullName: '11c. insurance plan name or program name',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insuredPolicyGroupFeCaPlanName',
        id: 'insuredPolicyGroupFeCaPlanName',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: '[11d] is there another health benefit plan?',
        fullName: '[11.d] is there another health benefit plan?',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isAnotherBenefitPlan',
        id: 'isAnotherBenefitPlan',
        subName: '',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: '[14]Date of current illness, injury:',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Date:',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'illnessDt'
          },
          {
            col: 'col-md-6',
            name: 'Code:',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'illnessCode',
          }
        ]
      },
      {
        col: 'col-md-4',
        name: '[15] if patient has had same or similar illness',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Date:',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'illnessOtherDate'
          },
          {
            col: 'col-md-6',
            name: 'Code:',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'illnessOtherCode'
          }
        ]
      },
      {
        col: 'col-md-4',
        name: '[16] dates patient unable to work in current occupation',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'From:',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'disableDateFrom',
          },
          {
            col: 'col-md-6',
            name: 'To:',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'disableDateTo'
          }
        ]
      },
    ]
  },
  {
    heading: '[17].Referring provider',
    type: GroupField.PROVIDER,
    key: 'referringId',
    service: false,
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        name: '[18]Hospitalization dates:',
        col: 'col-md-12',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-2',
            name: 'From:',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'hospitalDateFrom',
          },
          {
            col: 'col-md-2',
            name: 'To:',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'hospitalDateTo',
          },
          {
            col: 'col-md-4',
            name: '[19] Additions claim information',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'additionClaimInformation',
          },
          {
            col: 'col-md-2',
            name: '[20]Outside lab',
            type: FieldType.CHECKBOX,
            key: 'data',
            subKey: 'isOutSideLap',
            subName: ''
          },
          {
            col: 'col-md-2',
            name: '[20] $charges',
            type: FieldType.NUMBER,
            key: 'data',
            subKey: 'labAmt',
            subName: ''
          }
        ]
      }
    ],
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-12',
        name: '[21]Diagnosis or nature of illness or injury',
        type: FieldType.INPUT_GROUP,
        groups: [
          {
            col: 'col-md-3',
            name: 'A',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag1',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'B',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag2',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'C',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag3',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'D',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag4',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'E',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag5',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'F',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag6',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'G',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag7',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'H',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag8',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'I',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag9',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'J',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag10',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'K',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag11',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-3',
            name: 'L',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag12',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
        ],
      }
    ],
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: '[22] Resubmission Code',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'resubmissionCode',
        id: 'resubmissionCode',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: '[22] Original RefNo',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'originalRefNo',
        id: 'originalRefNo',
        subName: ''
      },
      {
        col: 'col-md-4',
        name: '[23] Prior authorization#',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'priorAuthorizationNum',
        id: 'priorAuthorizationNum',
        subName: ''
      },

    ]
  },
  {
    heading: '',
    type: GroupField.LINES,
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-3',
        name: '[25]. Federal Tax ID number',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'federalTaxNum',
        id: 'federalTaxNum',
        subName: ''
      },
      {
        col: 'col-md-3',
        name: '[26]. Patient Account No',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'patientAccNumber',
        id: 'patientAccNumber',
        subName: ''
      },
      {
        col: 'col-md-3',
        name: '[27]. Accept assignment?',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'acceptAssignment',
        id: 'acceptAssignment',
        subName: ''
      },
      {
        col: 'col-md-3',
        name: '[30].Balance due',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'rsvdForNuccUse',
        id: 'rsvdForNuccUse',
        subName: ''
      },
    ]
  },
  {
    heading: '[31]. Doctor',
    type: GroupField.PROVIDER,
    key: 'doctorId',
    subKey: '',
    service: false,
  },
  {
    heading: '[32]. Service Facility',
    type: GroupField.PROVIDER,
    key: 'payToId',
    subKey: '',
    service: false,
  },
  {
    heading: '[33]. Billing Provider',
    type: GroupField.PROVIDER,
    key: 'providerId',
    subKey: '',
    service: true,
  },
]