import { IBaseInvoice } from "@/core/entity/IBaseInvoice";

export interface IInvoiceUB04 extends IBaseInvoice {
  /**
   * [3b] Medical rec number:
   */
  medicalRecNumber: string
  /**
   * [4] Type of Bill
   */
  typeOfBill: string;

  /**
   * [6]Statement Covers Period
   */

  statementCPFrom: number     // statement covers period from
  statementCPThrough: number // statement covers period through
  //======= Admission ========//
  /**
   * Admission: [12]Date:
   */
  admissionDt: number
  /**
   * Admission : [13]HR
   */
  admissionHr: string;

  /**
   * [14]Type:
   */
  admissionType: string;
  /**
   * [15]SRC:
   */
  admissionSrc: string;
  /**
   * [16]DHR:
   */
  dhr: string;
  /**
   * [17]Stat:
   */
  stat: string;

  //======= Condition codes: ========//
  /**
   * [18] [19] [20] [21] => 28  code
   */
  cond1: string;
  cond2: string;
  cond3: string;
  cond4: string;
  cond5: string;
  cond6: string;
  cond7: string;
  cond8: string;
  cond9: string;
  cond10: string;

  /**
   * //Condition codes [29] STATE:
   */
  accidentState: string;

  /**
   * [30] future  use
   */
  futureUse30: string;

  //======= OCCURRENCE: ========//
  /**
   * [31] code
   */
  occurrence1: string;
  /**
   * [31] date
   */
  occurrence1Date: number;
  /**
   * [32] code
   */
  occurrence2: string;
  /**
   * [32] date
   */
  occurrence2Date: number
  /**
   * [33] code
   */
  occurrence3: string;
  /**
   * [33] date
   */
  occurrence3Date: number;
  /**
   * [34] code
   */
  occurrence4: string;
  /**
   * [34] date
   */
  occurrence4Date: number;


  //======= Occurrence span========//
  /**
   * [35]code
   */
  span1: string;
  /**
   * [35] from date
   */
  span1FromDate: number;
  /**
   * [35] to date
   */
  span1ToDate: number;
  /**
   * [36]code
   */
  span2: string;
  /**
   * [36] from date
   */
  span2FromDate: number;
  /**
   * [36] to date
   */
  span2ToDate: number;

  /**
   * [37] future Use
   */
  futureUse37: string;

  /**
   * [38] Responsible Party Name and Address
   */
  responsiblePtyNameAddress: string

  //======= Value Codes and Amounts ========//
  /**
   * 39-41
   * Value Codes and Amounts
   */
  value1Code: string;
  value1Amt: number
  value2Code: string;
  value2Amt: number
  value3Code: string;
  value3Amt: number;
  value4Code: string;
  value4Amt: number
  value5Code: string;
  value5Amt: number;
  value6Code: string;
  value6Amt: number;

  /**
   * [50] Payer Identification (Name)
   */
  payerName: string;
  /**
   * [51] Health Plan Identification Number
   */
  healthPlanId: string;

  /**
   * [52] Release of Info Certification
   */
  refInfo: string;
  /**
   * [53] Assignment of Benefit Certification
   */
  asgBen: string;
  /**
   * [54] Prior Payments
   */
  priorPayments: string;
  /**
   * [55] Estimated Amount Due
   */
  estAmountDue: string;

  /**
   * [56]NPI
   */
  npi: string;
  /**
   * [57] Other Provider IDs
   */
  otherPrvId: string;

  /**
   * [58] Insured’s Name
   */
  insuredName: string;
  /**
   * [59] Patient’s Relation to the Insured
   */
  patientRelationInsured: string;
  /**
   * [60] Insured’s Unique ID
   */
  insuredUniqueId: string;
  /**
   * [61] Insured Group Name
   */
  insuredGroupName: string;

  /**
   * [62] Insured Group Number
   */
  insuranceGroupNo: string;

  /**
   * [63] Treatment Authorization Codes
   */

  treatmentAuthCode: string;

  /**
   * [64] Document Control Number
   */
  docCtrNum: string;
  /**
   * [65] employer name
   */
  employerName: string;

  /**
   * [66] DX
   */
  diag5: string;
  diag6: string;
  diag7: string;
  diag8: string;
  diag9: string;
  diag10: string;
  diag11: string;
  diag12: string;
  diag13: string;
  diag14: string;
  diag15: string;
  diag16: string;
  diag17: string;
  diag18: string;
  diag19: string;
  diag20: string;
  /**
   * [67] Principal Diagnosis Code/Other Diagnosis
   Codes
   */
  poa1: boolean;
  poa2: boolean;
  poa3: boolean;
  poa4: boolean;
  poa5: boolean;
  poa6: boolean;
  poa7: boolean;
  poa8: boolean;
  poa9: boolean;
  poa10: boolean;
  poa11: boolean;
  poa12: boolean;
  poa13: boolean;
  poa14: boolean;
  poa15: boolean;
  poa16: boolean;
  poa17: boolean;
  poa18: boolean;
  poa19: boolean;
  poa20: boolean;
  /**
   * [69] Admitting Diagnosis Code
   */
  admitDxCode: string;
  /**
   * [70] Patient’s Reason for Visit Code
   */
  reasonDx: string;
  /**
   * [71] PPS Code
   */
  ppsCode: string;
  /**
   * [72] External Cause of Injury Code
   */
  externalInjuryCode: string;

  /**
   * [74] Principal Procedure Code/Date
   */
  principalProcedureCode: string;
  principalProcedureDate: number;
  otherProcedureCode1: string;
  otherProcedureDate1: number
  otherProcedureCode2: string;
  otherProcedureDate2: number;
  otherProcedureCode3: string;
  otherProcedureDate3: number;
  otherProcedureCode4: string;
  otherProcedureDate4: number;
  otherProcedureCode5: string;
  otherProcedureDate5: number;
}

export class ModelInvoiceUB implements IInvoiceUB04 {
  type: string = 'UB04'
  medicalRecNumber: string = '';
  accidentState: string = '';
  admissionDt: number = NaN
  admissionHr: string = '';
  admissionSrc: string = '';
  admissionType: string = '';
  admitDxCode: string = '';
  asgBen: string = '';
  cond1: string = '';
  cond10: string = '';
  cond2: string = '';
  cond3: string = '';
  cond4: string = '';
  cond5: string = '';
  cond6: string = '';
  cond7: string = '';
  cond8: string = '';
  cond9: string = '';
  dhr: string = '';
  diag1: string = '';
  diag10: string = '';
  diag11: string = '';
  diag12: string = '';
  diag13: string = '';
  diag14: string = '';
  diag15: string = '';
  diag16: string = '';
  diag17: string = '';
  diag18: string = '';
  diag19: string = '';
  diag2: string = '';
  diag20: string = '';
  diag3: string = '';
  diag4: string = '';
  diag5: string = '';
  diag6: string = '';
  diag7: string = '';
  diag8: string = '';
  diag9: string = '';
  docCtrNum: string = '';
  employerName: string = '';
  estAmountDue: string = '';
  externalInjuryCode: string = '';
  federalTaxNum: string = '';
  futureUse30: string = '';
  futureUse37: string = '';
  healthPlanId: string = '';
  insuranceGroupNo: string = '';
  insuredGroupName: string = '';
  insuredName: string = '';
  insuredUniqueId: string = '';
  npi: string = '';
  occurrence1: string = '';
  occurrence1Date: number = NaN;
  occurrence2: string = '';
  occurrence2Date: number = NaN;
  occurrence3: string = '';
  occurrence3Date: number = NaN;
  occurrence4: string = '';
  occurrence4Date: number = NaN;
  otherProcedureCode1: string = '';
  otherProcedureCode2: string = '';
  otherProcedureDate1: number = NaN;
  otherProcedureDate2: number = NaN;
  otherPrvId: string = '';
  patientAccNumber: string = '';
  patientRelationInsured: string = '';
  payerName: string = '';
  poa1: boolean = false;
  poa10: boolean = false;
  poa11: boolean = false;
  poa12: boolean = false;
  poa13: boolean = false;
  poa14: boolean = false;
  poa15: boolean = false;
  poa16: boolean = false;
  poa17: boolean = false;
  poa18: boolean = false;
  poa19: boolean = false;
  poa2: boolean = false;
  poa20: boolean = false;
  poa3: boolean = false;
  poa4: boolean = false;
  poa5: boolean = false;
  poa6: boolean = false;
  poa7: boolean = false;
  poa8: boolean = false;
  poa9: boolean = false;
  ppsCode: string = '';
  principalProcedureCode: string = '';
  principalProcedureDate: number = NaN;
  priorPayments: string = '';
  proc1: string = '';
  proc2: string = '';
  proc3: string = '';
  proc4: string = '';
  reasonDx: string = '';
  refInfo: string = '';
  responsiblePtyNameAddress: string = '';
  span1: string = '';
  span1FromDate: number = NaN;
  span1ToDate: number = NaN;
  span2: string = '';
  span2FromDate: number = NaN;
  span2ToDate: number = NaN;
  stat: string = '';
  statementCPFrom: number = NaN;
  statementCPThrough: number = NaN;
  treatmentAuthCode: string = '';
  typeOfBill: string = '';
  value1Amt: number = 0;
  value1Code: string = '';
  value2Amt: number = 0;
  value2Code: string = '';
  value3Amt: number = 0;
  value3Code: string = '';
  value4Amt: number = 0;
  value4Code: string = '';
  value5Amt: number = 0;
  value5Code: string = '';
  value6Amt: number = 0;
  value6Code: string = '';
  otherProcedureCode3: string = '';
  otherProcedureDate3: number = NaN;
  otherProcedureCode4: string = '';
  otherProcedureDate4: number = NaN;
  otherProcedureCode5: string = '';
  otherProcedureDate5: number = NaN;

}