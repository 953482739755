import { IBaseInvoice } from "@/core/entity/IBaseInvoice";

export interface IInvoiceHCFA extends IBaseInvoice {
  insuredNumber: string;
  isMedicare: boolean;
  isMedicaid: boolean;
  isTriCare: boolean;
  isChamPva: boolean;
  isGroupHealthPlan: boolean;
  isFeCaBlkLung: boolean;
  isOtherMedicare: boolean;
  otherInsuredName: string;
  otherInsuredPolicyOrGroupNumber: string;

  /**
   * [9b] OTHER INSURED’S DATE OF BIRTH
   */
  orderInsuredDob: number;

  /**
   * [9b] OTHER INSURED’S Gender;
   */
  orderInsuredGender: string

  /**
   * [9c] EMPLOYER’S NAME OR SCHOOL NAME
   */
  otherInsuredEmployerName: string;

  /**
   * [9d]  INSURANCE PLAN NAME OR PROGRAM NAME
   */
  insurancePlanNameOrProgramName: string;


  //===========[10]. Is patient's condition related to:============//
  /**
   * [10.a]  EMPLOYMENT? (CURRENT OR PREVIOUS)
   */
  isEmployment: boolean;

  /**
   * [10.b] Auto Accident;
   */
  isAutoAccident: boolean;

  /**
   * [10.b] Auto Accident place
   */
  autoAccidentPlace: string;

  /**
   * [10.c] OTHER ACCIDENT?
   */
  isOtherAccident: boolean;

  /**
   * 10d. RESERVED FOR LOCAL USE
   */
  reservedForLocalUse: string;

  //===============11. INSURED’S POLICY GROUP OR FECA NUMBER ==========//
  /**
   * [11]INSURED’S POLICY GROUP OR FECA NUMBER
   */
  insuredPolicyGroupOrFeCaNumber: string;
  /**
   * [11.a] date of birth
   */
  insuredPolicyGroupOrFeCaDob: string;
  /**
   * [11a] Gender
   */
  insuredPolicyGroupOrFeCaGender: string;

  /**
   * [11b] EMPLOYER’S NAME OR SCHOOL NAME
   */
  insuredPolicyGroupOrFeCaEmployerName: string

  /**
   * 11c. INSURANCE PLAN NAME OR PROGRAM NAME
   */
  insuredPolicyGroupFeCaPlanName: string;

  /**
   * 11.d IS THERE ANOTHER HEALTH BENEFIT PLAN?
   */

  isAnotherBenefitPlan: boolean;

  /**
   * [14] of current illness, injury:
   */
  illnessDt: number;
  illnessCode: string

  /**
   * 15 IF PATIENT HAS HAD SAME OR SIMILAR ILLNESS
   */
  illnessOtherDate: number
  illnessOtherCode: string

  /**
   * 16. DATES PATIENT UNABLE TO WORK IN CURRENT OCCUPATION
   */
  disableDateFrom: number;
  disableDateTo: number;

  //[17].Referring provider

  /**
   *  [18]Hospitalization dates:
   */
  hospitalDateFrom: number //from
  hospitalDateTo: number // to
  /**
   * [19] Additions claim information
   */
  additionClaimInformation: string;

  /**
   * [20]Outside lab:
   */

  isOutSideLap: boolean;

  /**
   * [20] $ charges
   */

  labAmt: number // // Form 1500 is an US standard form, so no need for a currency selector - always US

  /**
   * [21]Diagnosis or nature of illness or injury:
   */
  diag5: string;
  diag6: string;
  diag7: string;
  diag8: string;
  diag9: string;
  diag10: string;
  diag11: string;
  diag12: string;

  /**
   * [22] resubmission Code
   */
  resubmissionCode: string;

  /**
   * [22] original ref no
   */
  originalRefNo: string;

  /**
   * [23]  PRIOR AUTHORIZATION NUMBER
   */
  priorAuthorizationNum: string;

  /**
   * 27. ACCEPT ASSIGNMENT?
   * (For govt. claims, see back)
   */

  acceptAssignment: boolean;

  /**
   * 30 BALANCE DUE
   */
  rsvdForNuccUse: string;

}

export class ModelInvoiceHCFA implements IInvoiceHCFA {
  type: string  = 'HCFA'
  acceptAssignment: boolean = false;
  additionClaimInformation: string = '';
  autoAccidentPlace: string = '';
  diag1: string = '';
  diag10: string = '';
  diag11: string = '';
  diag12: string = '';
  diag2: string = '';
  diag3: string = '';
  diag4: string = '';
  diag5: string = '';
  diag6: string = '';
  diag7: string = '';
  diag8: string = '';
  diag9: string = '';
  disableDateFrom: number = NaN
  disableDateTo: number = NaN
  federalTaxNum: string = '';
  hospitalDateFrom: number = NaN
  hospitalDateTo: number = NaN
  illnessCode: string = '';
  illnessDt: number = NaN
  illnessOtherCode: string = '';
  illnessOtherDate: number = NaN
  insurancePlanNameOrProgramName: string = '';
  insuredNumber: string = '';
  insuredPolicyGroupFeCaPlanName: string = '';
  insuredPolicyGroupOrFeCaDob: string = '';
  insuredPolicyGroupOrFeCaEmployerName: string = '';
  insuredPolicyGroupOrFeCaGender: string = '';
  insuredPolicyGroupOrFeCaNumber: string = '';
  isAnotherBenefitPlan: boolean = false;
  isAutoAccident: boolean = false;
  isChamPva: boolean = false;
  isEmployment: boolean = false;
  isFeCaBlkLung: boolean = false;
  isGroupHealthPlan: boolean = false;
  isMedicaid: boolean = false;
  isMedicare: boolean = false;
  isOtherAccident: boolean = false;
  isOtherMedicare: boolean = false;
  isOutSideLap: boolean = false;
  isTriCare: boolean = false;
  labAmt: number = 0;
  orderInsuredDob: number = NaN;
  orderInsuredGender: string = '';
  originalRefNo: string = '';
  otherInsuredEmployerName: string = '';
  otherInsuredName: string = '';
  otherInsuredPolicyOrGroupNumber: string = '';
  patientAccNumber: string = '';
  priorAuthorizationNum: string = '';
  proc1: string = '';
  proc2: string = '';
  proc3: string = '';
  proc4: string = '';
  reservedForLocalUse: string = '';
  resubmissionCode: string = '';
  rsvdForNuccUse: string = '';

}