import { CURRENCY, FieldType, GroupField } from "@/core/config/Constant";


export const LostBaggageFieldConfig = [
  {
    heading: 'Invoice Information',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Currency',
        type: FieldType.SELECT,
        key: 'currency',
        subKey: '',
        id: 'currency',
        subName: '',
        data: CURRENCY,
      },
      {
        col: 'col-md-6',
        name: 'Date invoice received',
        type: FieldType.DATETIME,
        key: 'receivedDate',
        subKey: '',
        id: 'receivedDate',
        subName: '',
      },
    ]
  },
  {
    heading: 'CLAIMS DETAIL – Loss, Delay, Theft or Damage',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-12',
        name: 'Describe in details how where and when the loss occurred:',
        type: FieldType.TEXTAREA,
        key: 'data',
        subKey: 'describeInDetail',
        id: 'describeInDetail',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Was this loss reported? ',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isLostReported',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Hotel Management',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isReportToHotel',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Tour Guide   ',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isReportTourGuide',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Transportation Company',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isReportTransportationCompany',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Police',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isReportPolice',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Other (please specify)',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'reportOther',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Date the loss occurred',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'lossOccurredDate',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Trip departure date',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'departureDate',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Trip return date',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'returnDate',
        subName: '',
      },
    ]
  },
  {
    heading: 'Other insurance',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Was the trip purchased by a credit card?',
        type: FieldType.CHECKBOX,
        key: 'data',
        subKey: 'isPurchaseCreditCard',
        subName: '',
      },
      {
        col: 'col-md-6',
        name: 'If Yes, Credit Card Number',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'cardNumber',
        subName: '',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.LINES,
  }

]