
import {computed, onMounted, ref, watch} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {LoadEntity} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import BaseInvoiceForm from "@/views/invoice/BaseInvoiceForm.vue";
import store from "@/store";
import router from "@/router";
import {InvoiceType} from "@/core/config/Constant";
import {HCFAFieldConfig} from "@/core/entity/HCFAFieldConfig";
import {ModelInvoiceHCFA} from "@/core/entity/IInvoiceHCFA";
import {HCFALine} from "@/core/entity/HCFALine";
import {UB04FieldConfig} from "@/core/entity/UB04FieldConfig";
import {ModelInvoiceUB} from "@/core/entity/IInvoiceUB04";
import {UB04Line} from "@/core/entity/UB04Line";
import {DentalFieldConfig} from "@/core/entity/DentalFieldConfig";
import {ModelInvoiceDental} from "@/core/entity/IInvoiceDental";
import {ModelDentalLine} from "@/core/entity/IDentalLine";
import {TripCanFieldConfig} from "@/core/entity/TripCanFieldConfig";
import {ModelTripCanInvoice} from "@/core/entity/ITripCanInvoice";
import {TripCanLine} from "@/core/entity/TripCanLine";
import {PrescriptionFieldConfig} from "@/core/entity/PrescriptionFieldConfig";
import {ModelPrescriptionDrugs} from "@/core/entity/IPrescriptionDrugs";
import {LostBaggageFieldConfig} from "@/core/entity/LostBaggageFieldConfig";
import {ModelLostBaggageInvoice} from "@/core/entity/ILostBaggageInvoice";
import {LostBagLine} from "@/core/entity/LostBagLine";
import {GenericFieldConfig} from "@/core/entity/GenericFieldConfig";
import {ModelInvoiceGeneric} from "@/core/entity/IInvoiceGeneric";
import {GenericLine} from "@/core/entity/IGenericLine";
import {onUpdated} from "@vue/runtime-core";
import InvoiceLabel from "@/views/invoice/InvoiceLabel.vue";

export default {
  name: "ConvertStatement",
  components: {InvoiceLabel, BaseInvoiceForm},
  props: {
    convertType: {type: String, required: true}
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Update Statement', [
        {link: true, router: '/invoices/' + invoice?.value?.id, text: invoice?.value?.number},
        {link: false, router: '', text: 'Update Statement'}
      ])
    })
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Update Statement', [
        {link: true, router: '/invoices/' + invoice?.value?.id, text: invoice?.value?.number},
        {link: false, router: '', text: 'Update Invoice'}
      ])
    })
    const config = ref<any>();
    const data = ref({});
    const lines = ref<any>([]);
    const invoice = computed(() => store.state.InvoiceModule.invoice);
    const buildConfig = (type: string) => {
      lines.value = [];
      switch (type) {
        case InvoiceType.HCFA:
          config.value = HCFAFieldConfig;
          data.value = new ModelInvoiceHCFA();
          for (let i = 0; i < 6; i++) {
            lines.value.push(new HCFALine())
          }
          break
        case InvoiceType.UB04:
          config.value = UB04FieldConfig;
          data.value = new ModelInvoiceUB();
          for (let i = 0; i < 6; i++) {
            lines.value.push(new UB04Line())
          }
          break
        case InvoiceType.DENTAL:
          config.value = DentalFieldConfig;
          data.value = new ModelInvoiceDental();
          for (let i = 0; i < 3; i++) {
            lines.value.push(new ModelDentalLine())
          }
          break
        case InvoiceType.TRIP_CAN:
          config.value = TripCanFieldConfig
          data.value = new ModelTripCanInvoice();
          for (let i = 0; i < 6; i++) {
            lines.value.push(new TripCanLine())
          }
          break;
        case InvoiceType.PRESCRIPT:
          config.value = PrescriptionFieldConfig;
          data.value = new ModelPrescriptionDrugs()
          lines.value = [];
          break
        case InvoiceType.LOST_BAG:
          config.value = LostBaggageFieldConfig;
          data.value = new ModelLostBaggageInvoice();
          for (let i = 0; i < 6; i++) {
            lines.value.push(new LostBagLine())
          }
          break
        case 'GENERIC':
          config.value = GenericFieldConfig;
          data.value = new ModelInvoiceGeneric();
          for (let i = 0; i < 2; i++) {
            lines.value.push(new GenericLine())
          }
          break
      }
    }

    buildConfig(props.convertType);
    watch(() => props.convertType, (cb: string) => {
      buildConfig(cb);
    })
    const onSavedInvoice = (i: any) => {
      router.push({
        name: 'invoiceOverview',
        params: {id: i.id}
      })
    }


    return {
      ...LoadEntity(Actions.LOAD_INVOICE),
      invoice,
      onSavedInvoice,
      config,
      lines,
      data,
    }
  },
  methods: {
    buildLines(type, invoice) {

      switch (type) {
        case InvoiceType.DENTAL:
          if (invoice?.lines?.length === 0) {
            return new ModelDentalLine();
          } else {
            return invoice.lines.map(item => Object.assign(item, {
              type: 'DENTAL',
              amount: item.amount,
              cpt: item.cpt,
              desc: item.desc,
              diagnosis: item.diagnosis,
              emg: item.emg,
              fromDt: item.fromDt,
              modifier: item.modifier,
              pos: item.pos,
              repriced: item.repriced,
              rev: item.rev,
              toDt: item.toDt,
              units: item.units,
            }))
          }
        case InvoiceType.UB04:
          if (invoice.lines.length === 0) {
            return new UB04Line();
          } else {
            return invoice.lines.map(item => Object.assign(item, {
              type: 'UB04',
              amount: item.amount,
              cpt: item.cpt,
              desc: item.desc,
              diagnosis: item.diagnosis,
              emg: item.emg,
              fromDt: item.fromDt,
              modifier: item.modifier,
              pos: item.pos,
              repriced: item.repriced,
              rev: item.rev,
              toDt: item.toDt,
              units: item.units,
            }))
          }
        case InvoiceType.HCFA:
          if (invoice.lines.length === 0) {
            return new HCFALine();
          } else {
            return invoice.lines.map(item => Object.assign(item, {
              type: 'HCFA',
              amount: item.amount,
              cpt: item.cpt,
              desc: item.desc,
              diagnosis: item.diagnosis,
              emg: item.emg,
              fromDt: item.fromDt,
              modifier: item.modifier,
              pos: item.pos,
              repriced: item.repriced,
              rev: item.rev,
              toDt: item.toDt,
              units: item.units,
            }))
          }
        case InvoiceType.PRESCRIPT:
          return [];
        case InvoiceType.TRIP_CAN:
          if (invoice.lines.length === 0) {
            return new TripCanLine();
          } else {
            return invoice.lines.map(item => Object.assign(item, {
              type: 'TRIP_CAN',
              amount: item.amount,
              cpt: item.cpt,
              desc: item.desc,
              outstandingAmount: 0,
              offerBy: '',
              refund: 0,
              diagnosis: item.diagnosis,
              emg: item.emg,
              fromDt: item.fromDt,
              modifier: item.modifier,
              pos: item.pos,
              repriced: item.repriced,
              rev: item.rev,
              toDt: item.toDt,
              units: item.units,
            }))
          }
        case InvoiceType.LOST_BAG:
          if (invoice.lines.length === 0) {
            return new LostBagLine();
          } else {
            return invoice.lines.map(item => Object.assign(item, {
              type: 'LOST_BAG',
              amount: item.amount,
              cpt: item.cpt,
              desc: item.desc,
              diagnosis: item.diagnosis,
              emg: item.emg,
              fromDt: item.fromDt,
              modifier: item.modifier,
              pos: item.pos,
              repriced: item.repriced,
              rev: item.rev,
              toDt: item.toDt,
              units: item.units,
            }))
          }
        case 'GENERIC':
        case 'IVD':
        case 'IADD':
        case 'IT':
        case 'IMD':
        case 'IV':
        case 'IAA':
        case 'LA':
        case 'IO':
          if (invoice.lines.length === 0) {
            return new ModelInvoiceGeneric();
          } else {
            return invoice.lines.map(item => Object.assign(item, {
              type: 'GENERIC',
              amount: item.amount,
              cpt: item.cpt,
              desc: item.desc,
              diagnosis: item.diagnosis,
              emg: item.emg,
              fromDt: item.fromDt,
              modifier: item.modifier,
              pos: item.pos,
              repriced: item.repriced,
              rev: item.rev,
              toDt: item.toDt,
              units: item.units,
            }))
          }
        default:
          return []
      }

    }
  }
}
