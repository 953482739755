import { IBaseInvoice } from "@/core/entity/IBaseInvoice";

export interface ILostBaggageInvoice extends IBaseInvoice {
  describeInDetail: string
  isLostReported: boolean
  lossOccurredDate: number
  isReportToHotel: boolean
  isReportTourGuide: boolean
  isReportTransportationCompany: boolean
  isReportPolice: boolean
  reportOther: string
  departureDate: number
  returnDate: number
  isPurchaseCreditCard: boolean
  cardNumber: string;
}

export class ModelLostBaggageInvoice implements ILostBaggageInvoice {
  cardNumber: string = '';
  isPurchaseCreditCard: boolean = false;
  departureDate: number = NaN;
  describeInDetail: string = '';
  diag1: string = '';
  diag2: string = '';
  diag3: string = '';
  diag4: string = '';
  federalTaxNum: string = '';
  isLostReported: boolean = false;
  reportOther: string = '';
  isReportPolice: boolean = false;
  isReportToHotel: boolean = false;
  isReportTourGuide: boolean = false;
  isReportTransportationCompany: boolean = false;
  lossOccurredDate: number = NaN;
  patientAccNumber: string = '';
  proc1: string = '';
  proc2: string = '';
  proc3: string = '';
  proc4: string = '';
  returnDate: number = NaN;
  type: string = 'LOST_BAG';
}