import { IBaseLine } from "@/core/entity/IBaseLine";

export interface ITripCanLine extends IBaseLine {
  refund: number,
  offerBy: string,
  outstandingAmount: number
}

export class TripCanLine implements ITripCanLine {
  amount: number = 0;
  cpt: string = '';
  desc: string = '';
  diagnosis: string = '';
  emg: string = '';
  fromDt: number = NaN;
  modifier: string = '';
  offerBy: string = '';
  outstandingAmount: number = 0;
  pos: string = '';
  refund: number = 0;
  repriced: number = 0;
  rev: string = '';
  toDt: number = NaN;
  type: string = 'TRIP_CAN';
  units: number = 0;
}