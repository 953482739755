
import {Field} from "vee-validate";
import {computed, defineComponent, ref, watch} from "vue";
import store from "@/store";
import InvoiceService from "@/core/services/InvoiceService";
import {FieldType, InvoiceType, GroupField} from "@/core/config/Constant";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import BaseCheckBox from "@/components/base/checkbox/BaseCheckBox.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import InvoiceProviderSearch from "@/views/provider/InvoiceProviderSearch.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import FormCurrency from "@/components/base/currency/FormCurrency.vue";

export default defineComponent({
  name: "BaseInvoiceForm",
  components: {
    FormCurrency,
    FormErrorMessage,
    InvoiceProviderSearch, BaseRadio, DateTimeSelect, BaseCheckBox, BaseSelect, BaseForm, Field
  },
  props: {
    invoiceId: {type: String},
    invoiceData: {type: Object},
    config: {type: Object},
    isGeneric: {type: Boolean, required: false, default: false}
  },
  emits: ['savedInvoice'],
  setup(props, {emit}) {
    const model = ref<any>(props.invoiceData)
    const total = ref(0);
    const submitting = ref(false);
    const rates = computed(() => store.state.ExchangeRateModule.rates);
    const calculateTotal = () => {
      if (model?.value?.type === 'TRIP_CAN') {
        total.value = model?.value?.lines.map(item => Number(item.outstandingAmount)).reduce((prev, curr) => prev + curr, 0);
      } else {
        total.value = model?.value?.lines.map(item => Number(item.amount)).reduce((prev, curr) => prev + curr, 0);
      }

    }

    const addLine = () => {
      if (props.isGeneric) {
        model?.value.lines.push({
          type: 'GENERIC',
          fromDt: NaN,
          toDt: NaN,
          pos: '',
          emg: '',
          cpt: '',
          modifier: '',
          diagnosis: '',
          units: 0,
          amount: 0
        })
      }
      switch (model?.value?.type) {
        case InvoiceType.RX:
          model?.value.lines.push({
            desc: '',
            type: 'RX',
            fromDt: NaN,
            toDt: NaN,
            pos: '',
            emg: '',
            cpt: '',
            modifier: '',
            diagnosis: '',
            units: 0,
            amount: 0
          })
          break
        case InvoiceType.STATEMENT:
          model?.value.lines.push({
            type: 'STATEMENT',
            fromDt: NaN,
            toDt: NaN,
            pos: '',
            emg: '',
            cpt: '',
            modifier: '',
            diagnosis: '',
            units: 0,
            amount: 0
          })
          break
        case InvoiceType.DENTAL:
          model?.value.lines.push({
            type: 'DENTAL',
            fromDt: NaN,
            toDt: NaN,
            pos: '',
            emg: '',
            cpt: '',
            modifier: '',
            diagnosis: '',
            units: 0,
            amount: 0
          })
          break
        case InvoiceType.HCFA:
          model?.value.lines.push({
            type: 'HCFA',
            fromDt: NaN,
            toDt: NaN,
            pos: '',
            emg: '',
            cpt: '',
            modifier: '',
            diagnosis: '',
            units: 0,
            amount: 0
          })
          break
        case InvoiceType.UB04:
          model?.value?.lines.push({
            type: 'UB04',
            rev: '',
            desc: '',
            cpt: '',
            modifier: '',
            fromDt: NaN,
            units: 0,
            amount: 0
          })
          break
        case InvoiceType.TRIP_CAN :
          model.value.lines.push(
            {type: 'TRIP_CAN', desc: '', amount: 0, refund: 0, offerBy: '', outstandingAmount: 0}
          )
          break
      }
    }

    const calculateCADAmount = () => {
      let rate = rates.value.find(item => item.base === 'CAD' && item.currency === model.value?.currency);
      if (rate !== undefined) {
        const rxRate = (1 / rate.rate).toFixed(2);
        if (model.value?.data) {
          model.value.data.exRate = rxRate;
          model.value.data.cadAmount = (Number(rxRate) * model.value.data.totalClaim).toFixed(2);
        }
      } else {
        let rate = rates.value.find(item => item.base === model.value?.currency && item.currency === "CAD");
        if (rate != undefined) {
          if (model.value?.data) {
            model.value.data.exRate = rate.rate;
            model.value.data.cadAmount = (Number(rate.rate) * model.value.data.totalClaim).toFixed(2);
          }
        }
      }

    }
    const removeLine = (line) => {
      if (model.value?.lines) model.value.lines = model?.value?.lines.filter(item => item !== line)
      calculateTotal();
    }
    watch(() => props.invoiceData, () => {
      calculateTotal();
    })
    const save = () => {
      submitting.value = true;
      if (props.invoiceId) {
        InvoiceService.update(props.invoiceId, model.value).then(res => {
          emit('savedInvoice', res)
        }).finally(() => {
          submitting.value = false;
        })
      } else {
        InvoiceService.create(model.value).then(res => {
          emit('savedInvoice', res);
        }).finally(() => {
          submitting.value = false;
        })
      }
    }
    return {
      addLine,
      removeLine,
      save,
      model,
      submitting,
      total,
      calculateTotal,
      GroupField,
      FieldType,
      calculateCADAmount,
    }
  },
  methods: {
    populateBilling() {
      this.model.providerId = this.model.payToId
    },
    onPopulate(data, line) {
      if (!line.toDt) {
        line.toDt = data;
      }
    }
  }
})
