import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")!

  return (_ctx.currencies)
    ? (_openBlock(), _createBlock(_component_BaseSelect, {
        key: 0,
        data: _ctx.currencies,
        name: _ctx.name,
        modelValue: _ctx.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
        class: _normalizeClass(`form-select form-select-solid fw-bold`)
      }, {
        default: _withCtx(({option: option}) => [
          _createElementVNode("option", {
            value: option.value
          }, _toDisplayString(option.value) + " - " + _toDisplayString(option.name), 9, _hoisted_1)
        ]),
        _: 1
      }, 8, ["data", "name", "modelValue"]))
    : _createCommentVNode("", true)
}