import {IBaseInvoice} from "@/core/entity/IBaseInvoice";

export interface IInvoiceDental extends IBaseInvoice {
   otherDental :boolean
  nameSubscriber: string
  coverageDob: number;

  coverageGender: string
  coveragePolicyHolderId: string
  coverageGroupNumber: string
  coveragePatientRelationship: string;
  overageInsuranceCompany: string// 11:

  placeOfTreatment: string//  provider office, Hospital, ECF, Other
  isTreatmentOrthodontic: string
  monthsOfTreatmentRemain: string
  replacementOfPros: string
  dateAppliancePlaced: number
  datePriorPlacement: number
  treatmentResulting
  dateOfAccident: number
}

export class ModelInvoiceDental implements IInvoiceDental {
  otherDental :boolean = false
  coverageDob: number = NaN;
  coverageGender: string = '';
  coverageGroupNumber: string = '';
  coveragePatientRelationship: string = '';
  coveragePolicyHolderId: string = '';
  dateAppliancePlaced: number = NaN;
  dateOfAccident: number = NaN;
  datePriorPlacement: number = NaN;
  diag1: string = '';
  diag2: string = '';
  diag3: string = '';
  diag4: string = '';
  federalTaxNum: string = '';
  isTreatmentOrthodontic: string = '';
  monthsOfTreatmentRemain: string = '';
  nameSubscriber: string = '';
  overageInsuranceCompany: string = '';
  patientAccNumber: string = '';
  placeOfTreatment: string = '';
  proc1: string = '';
  proc2: string = '';
  proc3: string = '';
  proc4: string = '';
  replacementOfPros: string = '';
  treatmentResulting = '';
  type: string = 'DENTAL';
}