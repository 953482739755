import {CURRENCY, FieldType, GroupField, LookupType} from "@/core/config/Constant";

export const UB04FieldConfig = [
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: 'Currency',
        type: FieldType.SELECT,
        key: 'currency',
        subKey: '',
        id: 'currency',
        subName: '',
        data: CURRENCY,
      },
      {
        col: 'col-md-6',
        name: 'Date invoice received',
        type: FieldType.DATETIME,
        key: 'receivedDate',
        subKey: '',
        id: 'currency',
        subName: '',
      },
    ]
  },
  {
    heading: '[1] Service Provider',
    type: GroupField.PROVIDER,
    key: 'payToId',
    service: false,
  },
  {
    heading: '[2] Billing Provider',
    type: GroupField.PROVIDER,
    key: 'providerId',
    service: true,
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-2',
        name: '[3a] Patient ctr number:',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'patientAccNumber',
        id: 'patientAccNumber',
      },
      {
        col: 'col-md-2',
        name: '[3b] Medical rec number',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'medicalRecNumber',
        id: 'medicalRecNumber',
      },
      {
        col: 'col-md-2',
        name: '[4] Type',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'typeOfBill',
        id: 'typeOfBill',
      },
      {
        col: 'col-md-2',
        name: '[5] Federal Tax Number',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'federalTaxNum',
        id: 'federalTaxNum',
      },
      {
        col: 'col-md-2',
        name: '[6] Statement Covers Period From',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'statementCPFrom',
        id: 'statementCPFrom',
      },
      {
        col: 'col-md-2',
        name: '[6] Statement Covers Period To',
        type: FieldType.DATETIME,
        key: 'data',
        subKey: 'statementCPThrough',
        id: 'statementCPThrough',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        name: 'Admission',
        col: 'col-md-6',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-5',
            name: '[12] Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'admissionDt',
            id: 'admissionDt',
          },
          {
            col: 'col-md-1',
            name: '[13]HR',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'admissionHr',
            id: 'admissionHr',
          },
          {
            col: 'col-md-1',
            name: '[14]Type',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'admissionType',
            id: 'admissionType',
          },
          {
            col: 'col-md-1',
            name: '[15]SRC',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'admissionSrc',
            id: 'admissionSrc',
          },
          {
            col: 'col-md-2',
            name: '[16]DHR',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'dhr',
            id: 'dhr',
          },
          {
            col: 'col-md-2',
            name: '[17]Stat',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'stat',
            id: 'stat',
          },
        ]
      },
      {
        name: 'Condition codes',
        col: 'col-md-6',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-2',
            name: '[18]',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'cond1',
            id: 'cond1',
          },
          {
            col: 'col-md-2',
            name: '[19]',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'cond2',
            id: 'cond2',
          },
          {
            col: 'col-md-2',
            name: '[20]',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'cond3',
            id: 'cond3',
          },
          {
            col: 'col-md-2',
            name: '[21]',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'cond4',
            id: 'cond4',
          },
          {
            col: 'col-md-4',
            name: '[29] Accident State',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'accidentState',
            id: 'accidentState',
          },
        ],
      }

    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-3',
        name: '[31] OCCURRENCE',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-4',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'occurrence1',
            id: 'occurrence1',
          },
          {
            col: 'col-md-8',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'occurrence1Date',
            id: 'occurrence1Date',
          },
        ],
      },
      {
        col: 'col-md-3',
        name: '[32] OCCURRENCE',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-4',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'occurrence2',
            id: 'occurrence2',
          },
          {
            col: 'col-md-8',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'occurrence2Date',
            id: 'occurrence2Date',
          },
        ],
      },
      {
        col: 'col-md-3',
        name: '[33] OCCURRENCE',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-4',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'occurrence3',
            id: 'occurrence3',
          },
          {
            col: 'col-md-8',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'occurrence3Date',
            id: 'occurrence3Date',
          },
        ],
      },
      {
        col: 'col-md-3',
        name: '[34] OCCURRENCE',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-4',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'occurrence4',
            id: 'occurrence4',
          },
          {
            col: 'col-md-8',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'occurrence4Date',
            id: 'occurrence4Date',
          },
        ],
      }
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: '[35]Occurrence span 1',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-2',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'span1',
            id: 'span1',
          },
          {
            col: 'col-md-5',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'span1FromDate',
            id: 'span1FromDate',
          },
          {
            col: 'col-md-5',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'span1ToDate',
            id: 'span1ToDate',
          },
        ],
      },
      {
        col: 'col-md-6',
        name: '[36]Occurrence span 2',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-2',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'span2',
            id: 'span2',
          },
          {
            col: 'col-md-5',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'span2FromDate',
            id: 'span2FromDate',
          },
          {
            col: 'col-md-5',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'span2ToDate',
            id: 'span2ToDate',
          },
        ],
      }
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-6',
        name: '[38] Responsible Party Name and Address',
        type: FieldType.TEXTAREA,
        key: 'data',
        subKey: 'responsiblePtyNameAddress',
        size: 3
      },
      {
        col: 'col-md-2',
        name: '[39] Value codes',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'value1Code',
            id: 'value1Code',
          },
          {
            col: 'col-md-6',
            name: 'Amount',
            type: FieldType.NUMBER,
            key: 'data',
            subKey: 'value1Amt',
            id: 'value1Amt',
          }
        ]
      },
      {
        col: 'col-md-2',
        name: '[40] Value codes',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'value2Code',
            id: 'value2Code',
          },
          {
            col: 'col-md-6',
            name: 'Amount',
            type: FieldType.NUMBER,
            key: 'data',
            subKey: 'value2Amt',
            id: 'value2Amt',
          }
        ]
      },
      {
        col: 'col-md-2',
        name: '[41] Value codes',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'value3Code',
            id: 'value2Code',
          },
          {
            col: 'col-md-6',
            name: 'Amount',
            type: FieldType.NUMBER,
            key: 'data',
            subKey: 'value3Amt',
            id: 'value2Amt',
          }
        ]
      }
    ]
  },
  {
    heading: '',
    type: GroupField.LINES,
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-2',
        name: '[50] Payer name',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'payerName',
        id: 'payerName',
      },
      {
        col: 'col-md-2',
        name: '[51] Health plan Id',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'healthPlanId',
        id: 'healthPlanId',
      },
      {
        col: 'col-md-1',
        name: '[52] REL',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'refInfo',
        id: 'refInfo',
      },
      {
        col: 'col-md-1',
        name: '[52] REL',
        subName: '53] Assignment of Benefit Certification',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'asgBen',
        id: 'asgBen',
      },
      {
        col: 'col-md-2',
        name: '[54] Prior Payments',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'priorPayments',
        id: 'priorPayments',
      },
      {
        col: 'col-md-2',
        name: '[55] Estimated Amount Due',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'estAmountDue',
        id: 'estAmountDue',
      },
      {
        col: 'col-md-2',
        name: '[56] NPI',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'npi',
        id: 'npi',
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-2',
        name: '[57] Other Provider IDs',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'otherPrvId',
        id: 'otherPrvId',
      },
      {
        col: 'col-md-2',
        name: '[58] Insured\'s Name',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insuredName',
        id: 'insuredName',
      },
      {
        col: 'col-md-2',
        name: '[59] Patient’s Relation to ...',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'patientRelationInsured',
        id: 'patientRelationInsured',
      },
      {
        col: 'col-md-2',
        name: '[60] Insured’s Unique ID',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insuredUniqueId',
        id: 'insuredUniqueId',
      },
      {
        col: 'col-md-2',
        name: '[61] Group Name',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insuredGroupName',
        id: 'insuredGroupName',
      },
      {
        col: 'col-md-2',
        name: '[62] Insured Group Number',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'insuranceGroupNo',
        id: 'insuranceGroupNo',
      }
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: '[63] Treatment Authorization Codes',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'treatmentAuthCode',
        id: 'treatmentAuthCode',
      },
      {
        col: 'col-md-4',
        name: '[64] Document Control Number',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'docCtrNum',
        id: 'docCtrNum',
      },
      {
        col: 'col-md-4',
        name: '[65] employer name',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'employerName',
        id: 'employerName',
      }
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-12',
        name: '[66]DX',
        type: FieldType.INPUT_GROUP,
        groups: [
          {
            col: 'col-md-2',
            name: '',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag1',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-2',
            name: '',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag2',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-2',
            name: '',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag3',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-2',
            name: '',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag4',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-2',
            name: '',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag5',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-2',
            name: '',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag6',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-2',
            name: '',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag7',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-2',
            name: '',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag8',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-2',
            name: '',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag9',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-2',
            name: '',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag10',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-2',
            name: '',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag11',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
          {
            col: 'col-md-2',
            name: '',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'diag12',
            checkCode: true,
            lookupType: LookupType.ICD10CM
          },
        ]
      }
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-3',
        name: '[69] Admitting Diagnosis Code',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'admitDxCode',
        checkCode: true,
        lookupType: LookupType.ICD10CM
      },
      {
        col: 'col-md-3',
        name: '[70] Patient’s Reason for Visit Code',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'reasonDx',
        checkCode: true,
        lookupType: LookupType.ICD10CM
      },
      {
        col: 'col-md-3',
        name: '[71] PPS Code',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'ppsCode',
        checkCode: true,
        lookupType: LookupType.ICD10CM
      },
      {
        col: 'col-md-3',
        name: '[72] External Cause of Injury Code',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'externalInjuryCode',
        checkCode: true,
        lookupType: LookupType.ICD10CM
      },
    ]
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: '[74] Principal Procedure',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'principalProcedureCode',
            id: 'principalProcedureCode',
          },
          {
            col: 'col-md-5',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'principalProcedureDate',
            id: 'principalProcedureDate',
          },
        ],
      },
      {
        col: 'col-md-4',
        name: '[74a]Other Procedure',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'otherProcedureCode1',
            id: 'otherProcedureCode1',
          },
          {
            col: 'col-md-5',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'otherProcedureDate1',
            id: 'otherProcedureDate1',
          },
        ],
      },
      {
        col: 'col-md-4',
        name: '[74b]Other Procedure',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'otherProcedureCode2',
            id: 'otherProcedureCode2',
          },
          {
            col: 'col-md-5',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'otherProcedureDate2',
            id: 'otherProcedureDate2',
          },
        ],
      },
      {
        col: 'col-md-4',
        name: '[74c] Principal Procedure',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'otherProcedureCode3',
            id: 'otherProcedureCode2',
          },
          {
            col: 'col-md-5',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'otherProcedureDate3',
            id: 'otherProcedureDate3',
          },
        ],
      },
      {
        col: 'col-md-4',
        name: '[74d]Other Procedure',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'otherProcedureCode4',
            id: 'otherProcedureCode4',
          },
          {
            col: 'col-md-5',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'otherProcedureDate4',
            id: 'otherProcedureDate4',
          },
        ],
      },
      {
        col: 'col-md-4',
        name: '[74e]Other Procedure',
        type: FieldType.GROUP,
        groups: [
          {
            col: 'col-md-6',
            name: 'Code',
            type: FieldType.TEXT,
            key: 'data',
            subKey: 'otherProcedureCode5',
            id: 'otherProcedureCode5',
          },
          {
            col: 'col-md-5',
            name: 'Date',
            type: FieldType.DATETIME,
            key: 'data',
            subKey: 'otherProcedureDate5',
            id: 'otherProcedureDate5',
          },
        ],
      }
    ]
  },
  {
    heading: '[76] Attending Provider',
    type: GroupField.PROVIDER,
    key: 'referringId',
    subKey: '',
    service: false,
  },
  {
    heading: '[77] Operating Provider',
    type: GroupField.PROVIDER,
    key: 'doctorId',
    subKey: '',
    service: false,
  },
]