import { CURRENCY, FieldType, GroupField } from "@/core/config/Constant";

export const PrescriptionFieldConfig = [
  {
    heading: 'Provider',
    type: GroupField.PROVIDER,
    key: 'providerId',
    subKey: ''
  },
  {
    heading: '',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-4',
        name: 'Rx Number',
        type: FieldType.TEXT,
        key: 'data',
        subKey: 'rxNumber',
        id: 'rxNumber',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Date invoice received',
        type: FieldType.DATETIME,
        key: 'receivedDate',
        subKey: '',
        id: 'receivedDate',
        subName: '',
      },
      {
        col: 'col-md-4',
        name: 'Invoice Dos From',
        type: FieldType.DATETIME,
        key: 'dos',
        subKey: '',
        id: 'dos',
        subName: '',
      },
    ]
  },
  {
    heading: 'Total',
    type: GroupField.INPUT_GROUP,
    fields: [
      {
        col: 'col-md-3',
        name: 'Total Claim',
        type: FieldType.NUMBER,
        key: 'data',
        subKey: 'totalClaim',
        id: 'totalClaim',
        subName: '',
        function: {
          method: 'change',
          name: 'calculateCADAmount'
        }
      },
      {
        col: 'col-md-3',
        name: 'Currency',
        type: FieldType.SELECT,
        key: 'currency',
        subKey: '',
        id: 'currency',
        subName: '',
        data: CURRENCY,
      },
      {
        col: 'col-md-1',
        name: 'Ex Rate',
        type: FieldType.NUMBER,
        key: 'data',
        subKey: 'exRate',
        id: 'exRate',
        subName: '',
      },
      {
        col: 'col-md-2',
        name: 'CAD Amount',
        type: FieldType.NUMBER,
        key: 'data',
        subKey: 'cadAmount',
        id: 'cadAmount',
        subName: '',
      },
      {
        col: 'col-md-3',
        name: 'Client Paid',
        type: FieldType.NUMBER,
        key: 'data',
        subKey: 'clientPaid',
        id: 'clientPaid',
        subName: '',
      },
    ]
  }
]